.nav-brand {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 700;
  font-size: 25px;
  color: #034694;
}
.nav-brand-mob {
  font-family: 'IBM Plex Sans', sans-serif;
  font-weight: 600;
  font-size: 25px;
  color: #034694;
}
.nav-text {
  font-size: 15px;
  color: #000000;
  font-weight: 500;
}
.nav-text:hover {
  color: #000000;
  text-decoration: underline;
}
/* hide the normal navbar when the screen < 600px */
@media only screen and (max-width: 700px) {
  #navbar-normal {
    display: none;
  }
}
/* hide the mobile navbar when the screen > 600px */
@media only screen and (min-width: 700px) {
  #navbar-mobile,
  #nav-list-mob {
    display: none;
  }
}
.containerx {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 32px;
  height: 4px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.changex .bar1 {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.changex .bar2 {
  opacity: 0;
}

.changex .bar3 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.nav-link-mob:hover {
  background-color: #999999;
}
