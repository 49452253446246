body {
  font-family: 'Roboto', sans-serif;
}
@media only screen and (max-width: 1000px) {
  #margin-auto {
  }
}

.main-slogan {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 55px;
}
.secondary-slogan {
  font-weight: 400;
  font-size: 20px;
}
@media only screen and (max-width: 800px) {
  .main-slogan {
    font-weight: 500;
    font-size: 35px;
  }
  .secondary-slogan {
    font-weight: 400;
    font-size: 15px;
  }
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media only screen and (max-width: 1050px) {
  .vertical-center {
    position: absolute;
    margin-top: 150px;
  }
}
.btn-blue {
  background-color: #034694;
  color: aliceblue;
  height: 45px;
  width: 150px;
  font-weight: 500;
}
.btn-blue:hover {
  color: aliceblue;
  background-color: #1864bb;
}
.footer-col-header {
  color: #000000;
  font-size: 16px;
  font-weight: 500;
}
.footer-link {
  color: #000000;
  font-size: 13px;
}
.footer-link:hover {
  color: #000000;
  text-decoration: underline;
}
.copyright-text {
  color: #050505;
  font-size: 14px;
}
.cover {
  height: 90vh;
}
.page-heading {
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}
