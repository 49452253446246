.p-white{
    font-size: large;
    font-weight: 500;
}

.p-black{
    font-size: large;
    font-weight: 500;
     
}

.bold{
    font-weight: bolder;
}
.height-card{
    min-height: 450px;
}
 